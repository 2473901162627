import React from 'react';

class WeseTermsAndConditions extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FWese%20Terms%20and%20Conditions.pdf?alt=media&token=4f0a10d9-6121-4039-afc2-cd26a0ee2c5e"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default WeseTermsAndConditions;