import React from 'react';

class WesePrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FWese%20Privacy%20Policy.pdf?alt=media&token=70196e28-f064-4fd9-a87a-6cecbc2f0505"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default WesePrivacyPolicy;